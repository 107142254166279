<template>
    <v-card>
        <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
          <v-tabs-slider color="red"></v-tabs-slider>
          <!-- TAB 1 -->
          <v-tab href="#tab-1" v-if="$store.getters['rolesUser/getCustomRole'](111)">
            BUSCAR TRANSFERENCIA
            <v-icon>mdi-archive-search</v-icon>
          </v-tab>
          <!-- TAB 1 -->

          <!-- TAB 2 -->
          <v-tab href="#tab-2" v-if="$store.getters['rolesUser/getCustomRole'](114)">
            BUSCAR SOLICITUD DE TRANSFERENCIA
            <v-icon>mdi-file-search</v-icon>
          </v-tab>
          <!-- TAB 2 -->
        </v-tabs>

        <v-tabs-items v-model="tab">
          <!-- TAB 1 -->
          <v-tab-item value="tab-1">
            <v-card class="component-v-card" flat>
              <SearchTransferTable v-if="$store.getters['rolesUser/getCustomRole'](115)"/>
            </v-card>
          </v-tab-item>
          <!-- TAB 1 -->

          <!-- TAB 2 -->
          <v-tab-item value="tab-2">
            <v-card class="component-v-card" flat>
              <SearchTransferApplicationTable v-if="$store.getters['rolesUser/getCustomRole'](112)"/>
            </v-card>
          </v-tab-item>
          <!-- TAB 2 -->
        </v-tabs-items>
      </v-card>
</template>

<script>
import SearchTransferTable from "../tabs/SearchTransferTable.vue"
import SearchTransferApplicationTable from "../tabs/SearchTransferApplicationTable.vue"
import { mapMutations } from 'vuex'

export default {
  name: "SearchTransferPartial",

  components: {
    SearchTransferTable,
    SearchTransferApplicationTable
  },

  props: ['win'],

  data() {
    return {
      panel: 0,
      tab: null,
      callReload: false
    }
  },

  methods: {
    ...mapMutations({
            setReload: 'transferData/setReloadData'
        }),
    reload() {
      this.setReload()
    }
  },


}
</script>

<style scoped>
.component-v-card{
  border-radius: 15px;
}
</style>