<template>
  <v-expansion-panel v-model="panel">
    <!-- HEADER -->
    <v-expansion-panel-header class="mt-2">
      BUSCAR TRANSFERENCIA
      <template v-slot:actions>
        <v-btn class="mr-5" @click.native.stop="num++" icon small>
          <v-icon color="primary">mdi-reload</v-icon>
        </v-btn>
        <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <!-- HEADER -->

    <!-- CONTENT -->
    <v-expansion-panel-content>
      <SearchTransferPartial :win="win" :key="num"/>
    </v-expansion-panel-content>
    <!-- CONTENT -->
  </v-expansion-panel>
</template>

<script>
import SearchTransferPartial from "../partial/SearchTransferPartial.vue"

export default {
  name: "SearchTransfer",

  components: {
    SearchTransferPartial
  },

  props: ['win'],

  data() {
    return {
      panel: 0,
      num: 0,
    }
  },

}
</script>
