<template>
    <v-card class="pa-4 ma-1 white">
        <!-- Table -->
        <GeneralFilter class="background border-box" entity="transferencia"
        :filterEndpoint="$API.inventoryTransfer.filterTransfers" :search="search"
        @emptyFilter="getTransfers" @filtered="filterHandler">
            <DxDataGrid class="isScrolledByGeneralFilter" :data-source="transfer" key="ID_Transfer" :show-row-line="ShowRowLines" :show-border="showBorders"
                :row-alternation-enabled="rowAlternationEnabled" @exporting="onExporting" :paging="{ enabled: false }" style="max-height: 600px">
                <DxExport :enabled="true" />
                <DxScrolling row-rendering-mode="virtual" />
                <!--DxPaging :page-size="10" /-->
                <DxColumn :width="100" cell-template="show-template" caption="" v-if="$store.getters['rolesUser/getCustomRole'](113)"></DxColumn>
                <DxColumn data-field="ID_Transfer" caption="ID"></DxColumn>
                <DxColumn data-field="DateReq" caption="Fecha de solicitud"></DxColumn>
                <DxColumn data-field="DateTransfer" caption="Fecha de Aprobación"></DxColumn>
                <DxColumn data-field="OriginWarehouse" caption="Origen"></DxColumn>
                <DxColumn data-field="DestinyWarehouse" caption="Destino"></DxColumn>
                <DxColumn data-field="ReqName" caption="Usuario Solicita"></DxColumn>
                <DxColumn data-field="userName" caption="Usuario Aprobación"></DxColumn>
                <template #show-template="{ data }">
                    <a href="#" class="mx-1" @click.prevent.stop="showTransferDetails( data )">
                        <v-icon color="primary">mdi-eye</v-icon>
                    </a>
                </template>
            </DxDataGrid>
        </GeneralFilter>
        <!-- Table -->

        <!-- Modal -->
        <v-dialog v-model="showModal" width="75%" scrollable>
            <v-card>
                <v-card-text>
                    <div v-if="transferData">
                    <TransferDetails :transferData="transferData" @close-modal="showModal = false" :key="transferData.data.ID_Transfer" />
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Modal -->
    </v-card>
</template>

<script>
import { DxDataGrid, DxExport, DxColumn, DxScrolling } from 'devextreme-vue/data-grid'
import { formatDate } from '../../../helpers/formatDate'
import { printToExcel } from "../../../helpers/printToexcel"
import TransferDetails from '../TransferDetails.vue'
import GeneralFilter from '@/components/GeneralFilter.vue'
export default {
    name: 'SearchTransferTable',

    components: {
        DxDataGrid,
        DxColumn,
        DxExport,
        DxScrolling,
        TransferDetails,
        GeneralFilter
    },

    props: ['win'],

    data() {
        return {
            ShowRowLines: true,
            showBorders: true,
            rowAlternationEnabled: true,
            showModal: false,
            transfer: [],
            transferData: null,
            search: {
                filter: null
            }
        }
    },

    mounted() {
        this.getTransfers()
    },

    methods: {
        showTransferDetails(data) {
            this.showModal = true;
            this.transferData = data;
        },
        onExporting(e) {
            printToExcel(e, 'Transferencias de inventario')
        },

        getTransfers() {
            this.$API.inventoryTransfer
                .getAllTransfersHistory()
                .then(response => {
                    this.transfer = response.map(transfer => ({
                        ...transfer,
                        TransferDate: formatDate(transfer.TransferDate),
                    }))
                })
                .catch(error => {
                    console.log(error)
                })
        },
        filterHandler(response) {
            if (response.length > 0) {
                this.transfer = response.map(transfer => ({
                    ...transfer,
                    TransferDate: formatDate(transfer.TransferDate),
                }))
            } else {
                this.transfer = response
            }
        },

    },
}
</script>