<template>
    <v-card class="pa-4 ma-1 white">
        <!-- Table -->
        <GeneralFilter class="background border-box" entity="transferencia"
            :filterEndpoint="$API.inventoryTransfer.filterTransferRequests" :search="search"
            @emptyFilter="getAllTransferRequests" @filtered="filterHandler">
            <DxDataGrid class="isScrolledByGeneralFilter" :data-source="transferApplication" ref="dxDataGridTransferRequest" key="ID_Transfer"
                :show-row-line="ShowRowLines" :show-border="showBorders" :row-alternation-enabled="rowAlternationEnabled"
                @exporting="onExporting" :paging="{ enabled: false }" style="max-height: 600px">
                <DxExport :enabled="true" />
                <DxScrolling row-rendering-mode="virtual" />
                <!--DxPaging :page-size="10" /-->
                <DxColumn :width="100" cell-template="show-template" caption="" v-if="$store.getters['rolesUser/getCustomRole'](116)"></DxColumn>
                <DxColumn data-field="ID_Request" caption="ID"></DxColumn>
                <DxColumn cell-template="status-template" caption="Estado"></DxColumn>
                <DxColumn data-field="RequestDate" caption="Fecha de solicitud"></DxColumn>
                <DxColumn data-field="DateTransfer" caption="Fecha de Aprobación"></DxColumn>
                <DxColumn data-field="OriginWarehouse" caption="Origen"></DxColumn>
                <DxColumn data-field="DestinyWarehouse" caption="Destino"></DxColumn>
                <DxColumn data-field="ReqName" caption="Usuario Solicita"></DxColumn>
                <DxColumn data-field="userName" caption="Usuario Aprobación"></DxColumn>
                <template #show-template="{ data }">
                    <a href="#" class="mx-1" @click.prevent.stop="showModal = !showModal; transferData = data">
                        <v-icon color="primary">mdi-eye</v-icon>
                    </a>
                </template>
                <template #status-template="{ data }">
                    <div v-if="data.data.RequestStatus == 0">
                        <v-icon color="orange">mdi-clock-outline</v-icon> Pendiente
                    </div>
                    <div v-else-if="data.data.RequestStatus == 1">
                        <v-icon color="green">mdi-check-circle</v-icon> Realizado
                    </div>
                    <div v-else-if="data.data.RequestStatus == 2">
                        <v-icon color="red">mdi-close-circle</v-icon> No disponible
                    </div>
                    <div v-else>
                        <v-icon>mdi-alert-circle</v-icon> Error
                    </div>
                </template>
            </DxDataGrid>
        </GeneralFilter>
        <!-- Table -->

        <!-- Modal -->
        <v-dialog v-model="showModal" width="75%" scrollable>
            <v-card>
                <v-card-text>
                    <TransferDetails :transferData="transferData" :isTransferApplication="true"
                        @close-modal="() => showModal = false" @reload-table="reloadTable" />
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Modal -->
    </v-card>
</template>

<script>
import { DxDataGrid, DxExport, DxColumn, DxScrolling } from 'devextreme-vue/data-grid'
import { formatDate } from '../../../helpers/formatDate'
import { printToExcel } from '../../../helpers/printToexcel'
import TransferDetails from '../TransferDetails.vue'
import GeneralFilter from '@/components/GeneralFilter.vue'
export default {
    name: 'SearchTransferApplicationTable',

    components: {
        DxDataGrid,
        DxColumn,
        DxExport,
        DxScrolling,
        TransferDetails,
        GeneralFilter
    },

    props: ['win', 'reload'],

    data() {
        return {
            ShowRowLines: true,
            showBorders: true,
            rowAlternationEnabled: true,
            showModal: false,
            transferApplication: [],
            transferData: null,
            search: {
                filter: null
            }
        }
    },

    mounted() {
        this.getAllTransferRequests()
    },

    watch: {
        reload(newData) {
            console.log(newData)
            if (newData) {
                this.getAllTransferRequests()
                this.setReloadData()
            }
        },
    },

    methods: {
        onExporting(e) {
            printToExcel(e, 'Solicitudes de transferencias de inventario')
        },
        getAllTransferRequests() {
            this.$API.inventoryTransfer
                .getAllTransferRequestsHistory()
                .then(response => {
                    this.transferApplication = response.map(transfer => ({
                        ...transfer,
                        RequestDate: formatDate(transfer.RequestDate),
                    }))
                })
                .catch(error => {
                    console.log(error)
                })
        },
        reloadTable() {
            this.getAllTransferRequests()
            this.$refs['dxDataGridTransferRequest'].instance.refresh()
        },
        filterHandler(response) {
            if (response.length > 0) {
                this.transferApplication = response.map(transfer => ({
                    ...transfer,
                    TransferDate: formatDate(transfer.TransferDate),
                }))
            } else {
                this.transferApplication = response
            }
        },
    },
}
</script>